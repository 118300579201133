<template>
  <div class="ct dd">
    <h2><router-link to="/fingerprint" tag="i">←返回</router-link>删除全部指纹</h2>
    <div class="detail">
      <div class="step k">
        <div class="ti">用户操作步骤</div>
        <dl>
          <dt>上拉滑盖上电</dt>
          <dt class="jt">↓</dt>
          <dt>按<i class="red">“3”</i>键</dt>
          <dt class="jt">↓</dt>
          <dt>按<i class="red">“#”</i>键</dt>
          <dt class="jt">↓</dt>
          <dt>输入8位<i class="red">母码</i></dt>
          <dt class="jt">↓</dt>
          <dt>按<i class="red">“#”</i>键</dt>
          <dt class="jt">↓</dt>
          <dt>指纹全部删除成功</dt>
        </dl>
      </div>
      <div class="status k">
        <div class="ti">锁的提示状态</div>
        <dl>
          <dt>蓝光灯亮，或语音提示<i class="red">“欢迎光临”</i></dt>
          <dt class="jt">↓</dt>
          <dt><i class="red">“滴”</i>声响</dt>
          <dt class="jt">↓</dt>
          <dt><i class="red">“滴”</i>声响</dt>
          <dt class="jt">↓</dt>
          <dt>伴随输入<i class="red">“滴”</i>声响8次</dt>
          <dt class="jt">↓</dt>
          <dt>指纹采集窗蓝光灯亮</dt>
          <dt class="jt">↓</dt>
          <dt>语音提示<i class="red">“操作成功”</i>，密码修改成功</dt>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>


export default {
    name: 'rootPassword',
    data() {
        return {}
    },
    created() {



    },
}
</script>

